import NavigationBar from './NavigationBar';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './TranslatedApp.css';

function TranslatedApp({ children }) {
  const { i18n } = useTranslation();
  const [updatingTranslations, setUpdatingTranslations] = useState(false);

  const handleLanguageChange = async (language) => {
    const container = document.getElementById('container');

    container.classList.add('updating-translations');
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 500ms
    await i18n.changeLanguage(language);
    container.classList.remove('updating-translations');
    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 500ms
    setUpdatingTranslations(false);
  };

  return (
    <div>
      <NavigationBar onLanguageChange={handleLanguageChange} />
      <div
        id="container"
        className={`container ${updatingTranslations ? 'updating-translations' : ''}`}
      >
        {children}
      </div>
    </div>
  );
}

export default TranslatedApp;