import './Take.css';
import VoteValue from './VoteValue.js'
import React, { useRef, useState } from 'react';
import VotesBar from './VotesBar'
import { useTranslation } from 'react-i18next';

const Take = ({ take, toggleVotesDetail }) => {
  
  const [userVote, setUserVote] = useState(null);

  const { t, i18n } = useTranslation();

  const translatedTakeText = () => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      return take.text_en;
    } else if (currentLanguage === 'de') {
      return take.text_de;
    } else if (currentLanguage === 'fr') {
      return take.text_fr;
    } else {
      return take.text_en; // Default to English if language is not available
    }
  };

  const translatedTakeTopicTitle = () => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      return take.topic.title_en;
    } else if (currentLanguage === 'de') {
      return take.topic.title_de;
    } else if (currentLanguage === 'fr') {
      return take.topic.title_fr;
    } else {
      return take.topic.title_en; // Default to English if language is not available
    }
  };

  const translatedTakeUserTitle = () => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
      return take.user.userTitle_en;
    } else if (currentLanguage === 'de') {
      return take.user.userTitle_de;
    } else if (currentLanguage === 'fr') {
      return take.user.userTitle_fr;
    } else {
      return take.user.userTitle_en; // Default to English if language is not available
    }
  };

  const translatedGroupName = (group) => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
    return group.name_en;
    } else if (currentLanguage === 'de') {
    return group.name_de;
    } else if (currentLanguage === 'fr') {
    return group.name_fr;
    } else {
    return group.name_en; // Default to English if language is not available
    }
}

  const vote = (value) => {
    console.log(`vote() value: ${value}`);
    setUserVote(value);
  };

  return (
    <div className="take">
      <div className="take-card">
        <div className="take-content">
          <p className="take-text">"{translatedTakeText()}"</p>

          <div className="take-topic-container">
            <div className="take-topic-container-inner">
              <p className="take-topic">{translatedTakeTopicTitle()}</p>
            </div>
          </div>

          <div className="take-user">
            <img className="take-user-img" src={take.user.userImg} />
            <div className="take-user-info">
              <h3 className="take-user-name">{take.user.username}</h3>
              <h5 className="take-user-title">{translatedTakeUserTitle()}</h5>
            </div>
          </div>

          <div className="take-votes" onClick={toggleVotesDetail}>
            {take.groupVotes[0] ? (
                <div className="take-votes-group">
                  <h5 className="take-votes-label">{translatedGroupName(take.groupVotes[0])}</h5>
                  <VotesBar 
                    numberStronglyDisagree={take.groupVotes[0].votes.number_strongly_disagree}
                    numberSomewhatDisagree={take.groupVotes[0].votes.number_somewhat_disagree}
                    numberNeutral={take.groupVotes[0].votes.number_neutral}
                    numberSomewhatAgree={take.groupVotes[0].votes.number_somewhat_agree}
                    numberStronglyAgree={take.groupVotes[0].votes.number_strongly_agree}
                  />
                </div>
            ) : (
                <></>
            )}
            
            {take.groupVotes[1] ? (
                <div className="take-votes-group">
                  <h5 className="take-votes-label">{translatedGroupName(take.groupVotes[1])}</h5>
                  <VotesBar 
                    numberStronglyDisagree={take.groupVotes[1].votes.number_strongly_disagree}
                    numberSomewhatDisagree={take.groupVotes[1].votes.number_somewhat_disagree}
                    numberNeutral={take.groupVotes[1].votes.number_neutral}
                    numberSomewhatAgree={take.groupVotes[1].votes.number_somewhat_agree}
                    numberStronglyAgree={take.groupVotes[1].votes.number_strongly_agree}
                  />
                </div>
            ) : (
                <></>
            )}

            <p className="take-votes-info-label">
              {t('button.clickForDetails')}
            </p>
          </div>
        </div>
      </div>

      <div className="take-voting-bar">
        <div className={`take-voting-step ${userVote ? (userVote === VoteValue.STRONGLY_DISAGREE ? 'selected' : 'unselected') : ''} stronglyDisagree`} onClick={() => vote(VoteValue.STRONGLY_DISAGREE)}>
          <h5 className="take-voting-step-label">{t('button.stronglyDisagree')}</h5>
        </div>
        <div className={`take-voting-step ${userVote ? (userVote === VoteValue.SOMEWHAT_DISAGREE ? 'selected' : 'unselected') : ''} somewhatDisagree`} onClick={() => vote(VoteValue.SOMEWHAT_DISAGREE)}>
          <h5 className="take-voting-step-label">{t('button.somewhatDisagree')}</h5>
        </div>
        <div className={`take-voting-step ${userVote ? (userVote === VoteValue.NEUTRAL ? 'selected' : 'unselected') : ''} neutral`} onClick={() => vote(VoteValue.NEUTRAL)}>
          <h5 className="take-voting-step-label">{t('button.neutral')}</h5>
        </div>
        <div className={`take-voting-step ${userVote ? (userVote === VoteValue.SOMEWHAT_AGREE ? 'selected' : 'unselected') : ''} somewhatAgree`} onClick={() => vote(VoteValue.SOMEWHAT_AGREE)}>
          <h5 className="take-voting-step-label">{t('button.somewhatAgree')}</h5>
        </div>
        <div className={`take-voting-step ${userVote ? (userVote === VoteValue.STRONGLY_AGREE ? 'selected' : 'unselected') : ''} stronglyAgree`} onClick={() => vote(VoteValue.STRONGLY_AGREE)}>
          <h5 className="take-voting-step-label">{t('button.stronglyAgree')}</h5>
        </div>
      </div>
    </div>
  );
};

export default Take;