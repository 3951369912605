import './LandingPage.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function LandingPage() {
  const { t, i18n } = useTranslation();

  return (
    <div className="landing">
        <h1 className="landing-title">{t('landing.welcome')}</h1>
        <p className="landing-text">
            {t('landing.text')}
        </p>
        <Link to="/onboarding">
        <button className="landing-button-right">{t('button.next')}</button>
      </Link>
    </div>
  );
}

export default LandingPage;