import LanguageSwitcher from './LanguageSwitcher';
import './NavigationBar.css';

function NavigationBar({ onLanguageChange }) {

  
    return (
        <div className="navbar">
          <h1 className="navbar-title">Demo Munich Security Conference 2025</h1>

          <LanguageSwitcher onLanguageChange={onLanguageChange} />

          <hr className="navbar-separator" />
        </div>
      );
  }

  export default NavigationBar;