import './Takes.css';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import Take from './Take.jsx';
import VotesDetail from './VotesDetail.jsx'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

import takesData from './takesData.json';


export default function Takes() {
  const { t, i18n } = useTranslation();

  const [currentTakeIndex, setCurrentTakeIndex] = useState(0);
  const sliderRef = useRef(null);

  const [navigating, setNavigating] = useState(false);

  const NextArrow = ({ onClick }) => {
    return (
      !navigating && currentTakeIndex < takesData.data.length - 1 && (
        <div className="arrow next" onClick={onClick}>
          <img src="../img/icons/arrow-right.png" />
        </div>
      )
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      !navigating && currentTakeIndex > 0 && (
        <div className="arrow prev" onClick={onClick}>
          <img src="../img/icons/arrow-left.png" />
        </div>
      )
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: () => setNavigating(true),
    afterChange: (index) => {
      setCurrentTakeIndex(index);
      setNavigating(false);
    },
  };

  const [showVotesDetail, setShowVotesDetail] = useState(false);

  const toggleVotesDetail = () => {
    console.log(`toggleVotesDetail, currentTakeIndex: ${currentTakeIndex}`);
    setShowVotesDetail(!showVotesDetail);
  };

  return (
    <div className="takes">
    <div className="takes-slider">
        <Slider ref={sliderRef} {...settings}>
            
            {takesData.data.map((take, index) => (
            <Take
                key={index}
                take={take}
                toggleVotesDetail={toggleVotesDetail} // pass callback function as a prop
            />
            ))}
        </Slider>
    </div>
    <VotesDetail
      groupVotes={takesData.data[currentTakeIndex].groupVotes}
      show={showVotesDetail}
      onClose={toggleVotesDetail}
    />
  </div>
  );
}
