import { useState } from 'react';
import './OnboardingQuestion.css';
import { useTranslation } from 'react-i18next';

export default function OnboardingQuestion({ title, text, options, onSelect, selectedOption: initialSelectedOption }) {
    const { t, i18n } = useTranslation();

    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
    const [listOffset, setListOffset] = useState(0);

    const handleScroll = (e) => {
        const itemHeight = 30; // Height of each item in the list
        const maxOffset = (options.length - 3) * itemHeight; // Maximum scroll offset
        const newOffset = listOffset + e.deltaY; // Calculate new scroll offset based on wheel event

        if (newOffset < 0) {
        setListOffset(0); // Don't scroll past the first item
        } else if (newOffset > maxOffset) {
        setListOffset(maxOffset); // Don't scroll past the last item
        } else {
        setListOffset(newOffset);
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onSelect(option);
    };    


    return (
        <div className="onboarding-question">
            <h1 className="onboarding-question-title">{title}</h1>
            <p className="onboarding-question-text">{text}</p>
            <div className="onboarding-question-options-wrapper" onWheel={handleScroll}>
                <div className="onboarding-question-options" style={{ top: -listOffset }}>
                    {options.map((option) => (
                        <div
                            key={option.id}
                            className={`onboarding-question-option${option.id === selectedOption ? ' selected' : ''}`}
                            onClick={() => handleOptionClick(option.id)}
                        >
                            <div className="onboarding-question-option-content">
                            {option.value}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
      </div>
    );
}


