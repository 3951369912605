import './VotesDetail.css';
import { useState, useEffect } from 'react';
import VotesBar from './VotesBar.jsx'
import React from 'react';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';

const VotesDetail = ({ groupVotes, show, onClose }) => {
    const { t, i18n } = useTranslation();

    const [selectedVotesGroup, setSelectedVotesGroup] = useState(0);
    const [selectedVotesGroupIndex, setSelectedVotesGroupIndex] = useState(0);
    const [selectedChartSlice, setSelectedChartSlice] = useState(null);

    useEffect(() => {
    
        // Set first Group as initially selected Votes Group.
        if (groupVotes && groupVotes[0]) {
            if (groupVotes[0].groups && groupVotes[0].groups[0]) {
                setSelectedVotesGroup(groupVotes[0].groups[0]);
                const index = {
                    "parentGroupIndex": 0,
                    "childGroupIndex": 0
                }
                setSelectedVotesGroupIndex(index);
            }
            else if (groupVotes[0].votes) {
                setSelectedVotesGroup(groupVotes[0]);
                const index = {
                    "parentGroupIndex": 0,
                    "childGroupIndex": null
                }
                setSelectedVotesGroupIndex(index);
            }
        }
        setSelectedChartSlice(null);
        console.log(`selectedVotesGroup: ${JSON.stringify(selectedVotesGroup, null, '\t')}`);
    }, [groupVotes]);

    const didSelectVotesGroup = (parentGroupIndex, childGroupIndex) => {
        console.log(`didSelectVotesGroup: ${parentGroupIndex}, ${childGroupIndex}`);

        setSelectedChartSlice(null);
        const index = {
            "parentGroupIndex": parentGroupIndex,
            "childGroupIndex": childGroupIndex
        }
        setSelectedVotesGroupIndex(index);
        if (parentGroupIndex != null && childGroupIndex == null) {
            setSelectedVotesGroup(groupVotes[parentGroupIndex]);
        }
        else if (parentGroupIndex != null && childGroupIndex != null) {
            setSelectedVotesGroup(groupVotes[parentGroupIndex].groups[childGroupIndex]);
        }
        console.log(`selectedVotesGroup: ${JSON.stringify(selectedVotesGroup, null, '\t')}`);
    }

    const didSelectChartSlice = (index) => {
        console.log(`didSelectChartSlice ${index}`);
        setSelectedChartSlice(index);
    }

    const translatedGroupName = (group) => {
        if (group.name_en && group.name_de && group.name_fr) {
            const currentLanguage = i18n.language;
            if (currentLanguage === 'en') {
                return group.name_en;
            } else if (currentLanguage === 'de') {
                return group.name_de;
            } else if (currentLanguage === 'fr') {
                return group.name_fr;
            } else {
                return group.name_en; // Default to English if language is not available
            }
        }
        return group.name;
    }

    const votesGroupDetailText = () => {
        if (selectedVotesGroup) {
            if (selectedVotesGroupIndex.parentGroupIndex != null && selectedVotesGroupIndex.childGroupIndex == null) {
                return `${translatedGroupName(selectedVotesGroup)}`;
            }
            else if (selectedVotesGroupIndex.parentGroupIndex != null && selectedVotesGroupIndex.childGroupIndex != null) {
                return `${translatedGroupName(groupVotes[selectedVotesGroupIndex.parentGroupIndex])}: ${translatedGroupName(selectedVotesGroup)}`;
            }
        }
    }

    const chartDetailText = () => {
        if (selectedChartSlice !== null && selectedVotesGroup) {
            switch (selectedChartSlice) {
                case 0:
                    return `${t('votes.stronglyDisagree')}: ${selectedVotesGroup.votes.number_strongly_disagree}`;
                case 1:
                    return `${t('votes.somewhatDisagree')}: ${selectedVotesGroup.votes.number_somewhat_disagree}`;
                case 2:
                    return `${t('votes.neutral')}: ${selectedVotesGroup.votes.number_neutral}`;
                case 3:
                    return `${t('votes.somewhatAgree')}: ${selectedVotesGroup.votes.number_somewhat_agree}`;
                case 4:
                    return `${t('votes.stronglyAgree')}: ${selectedVotesGroup.votes.number_strongly_agree}`;
                default:
                  return "";
            }
        }
    }

    var chartConfig = {
        series: selectedVotesGroup ? [
            selectedVotesGroup.votes.number_strongly_disagree,
            selectedVotesGroup.votes.number_somewhat_disagree,
            selectedVotesGroup.votes.number_neutral,
            selectedVotesGroup.votes.number_somewhat_agree,
            selectedVotesGroup.votes.number_strongly_agree
        ] : [],
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 400,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        options: {
          chart: {
            type: 'donut',
            parentHeightOffset: 0,
            events: {
                dataPointSelection: function(event, chartContext, config) {
                    didSelectChartSlice(config.dataPointIndex);
                    console.log(config.dataPointIndex);
                }
              },
          },
          colors: ["#DF4640", "#E8A532", "#F5CD49", "#8EC94D", "#43BC55"],
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '10px',
              fontFamily: 'Poppins, sans-serif',
              textShadow: 'none',
            },
            background: {
                dropShadow: false
            },
            dropShadow: {
                enabled: false,
            }
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: ['#1F2833'],
            width: 5,
            dashArray: 0,  
          },
          legend: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
          },
          responsive: [{
            breakpoint: 100,
            options: {
              chart: {
                width: 50
              },
            }
          }]
        },
    };


    return (
        <div className={`votes-detail ${show ? 'show' : ''}`}>
            <div className="votes-content">
                <div className="votes-detail-header">

                    <h5 className="votes-group-detail-label">
                        {selectedVotesGroup ? (
                            <> {votesGroupDetailText()} </>
                        ) : (
                            <></>
                        )}
                    </h5>

                    <button className="close-button" onClick={onClose}>
                        {t('button.close')}
                    </button>
                </div>


                <div className="votes-chart">

                    {selectedVotesGroup ? (
                        <ReactApexChart
                            options={chartConfig.options}
                            series={chartConfig.series}
                            type="donut"
                            height="100%"
                            width="100%"
                        />
                    ) : (
                        <></>
                    )}
                    
                    <h5 className="votes-chart-detail-label">
                        {selectedChartSlice !== null ? (
                            <> {chartDetailText()} </>
                        ) : (
                            <></>
                        )}
                    </h5>

                </div>

                <div className="votes-groups">
                    {groupVotes.map((parentGroup, parentGroupIndex) => (
                        <>

                        { parentGroup.groups ? (
                            // Group with sub groups.

                            <React.Fragment key={parentGroupIndex}>
                            <h5 className="votes-detail-parent-group-label">{t('votes.detail.by')} {translatedGroupName(parentGroup)}</h5>

                            {parentGroup.groups.map((childGroup, childGroupIndex) => (
        
                                <React.Fragment key={childGroupIndex}>
                                <div className={`votes-detail-group ${selectedVotesGroupIndex.parentGroupIndex === parentGroupIndex && selectedVotesGroupIndex.childGroupIndex === childGroupIndex ? 'selected' : ''}`} onClick={() => didSelectVotesGroup(parentGroupIndex, childGroupIndex)}>
        
                                    <h5 className="votes-detail-group-label">{translatedGroupName(childGroup)}</h5>
        
                                    <VotesBar
                                        key={`parent-${parentGroupIndex}-child-${childGroupIndex}-${JSON.stringify(childGroup)}`}
                                        numberStronglyDisagree={childGroup.votes.number_strongly_disagree}
                                        numberSomewhatDisagree={childGroup.votes.number_somewhat_disagree}
                                        numberNeutral={childGroup.votes.number_neutral}
                                        numberSomewhatAgree={childGroup.votes.number_somewhat_agree}
                                        numberStronglyAgree={childGroup.votes.number_strongly_agree}
                                    />
        
                                </div>
                                </React.Fragment>
                            ))}
                            </React.Fragment>
                        ) : (
                            // Group with no sub groups.
                            
                            <div className={`votes-detail-group ${selectedVotesGroupIndex.parentGroupIndex === parentGroupIndex && selectedVotesGroupIndex.childGroupIndex === null ? 'selected' : ''}`}onClick={() => didSelectVotesGroup(parentGroupIndex, null)}>
        
                                <h5 className="votes-detail-group-label">{translatedGroupName(parentGroup)}</h5>

                                <VotesBar
                                    key={`parent-${parentGroupIndex}-${JSON.stringify(parentGroup)}`}
                                    numberStronglyDisagree={parentGroup.votes.number_strongly_disagree}
                                    numberSomewhatDisagree={parentGroup.votes.number_somewhat_disagree}
                                    numberNeutral={parentGroup.votes.number_neutral}
                                    numberSomewhatAgree={parentGroup.votes.number_somewhat_agree}
                                    numberStronglyAgree={parentGroup.votes.number_strongly_agree}
                                />

                            </div>

                        )}

                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default VotesDetail;