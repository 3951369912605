import './VotesBar.css';
import { useState, useEffect } from 'react';


const VotesBar = ({ numberStronglyDisagree, numberSomewhatDisagree, numberNeutral, numberSomewhatAgree, numberStronglyAgree }) => {

    const minimumBarPercentage = 5;

    const [stronglyDisagreePercentage, setStronglyDisagreePercentage] = useState(0);
    const [somewhatDisagreePercentage, setSomewhatDisagreePercentage] = useState(0);
    const [neutralPercentage, setNeutralPercentage] = useState(0);
    const [somewhatAgreePercentage, setSomewhatAgreePercentage] = useState(0);
    const [stronglyAgreePercentage, setStronglyAgreePercentage] = useState(0);

    const [shouldDrawBar, setShouldDrawBar] = useState(false);

    useEffect(() => {
        updateVotes();
      }, [numberStronglyDisagree, numberSomewhatDisagree, numberNeutral, numberSomewhatAgree, numberStronglyAgree]);

    const updateVotes = () => {
        // console.log(`numberStronglyDisagree: ${numberStronglyDisagree}`);
        // console.log(`numberSomewhatDisagree: ${numberSomewhatDisagree}`);
        // console.log(`numberNeutral: ${numberNeutral}`);
        // console.log(`numberSomewhatAgree: ${numberSomewhatAgree}`);
        // console.log(`numberStronglyAgree: ${numberStronglyAgree}`);

        var numberTotalVotes = totalNumberVotes();
        const numberBelowVotesTreshold = calculateNumberBelowVotesTreshold(numberTotalVotes);
        const scale = numberTotalVotes / (1 - (numberBelowVotesTreshold * (minimumBarPercentage / 100)));

        // Change: Calculate adjusted total percentage
        const adjustedTotalPercentage = 100 - (numberBelowVotesTreshold * minimumBarPercentage);

        // Change: Update percentage calculations to use adjustedTotalPercentage
        if (((numberStronglyDisagree / scale) * 100) < minimumBarPercentage) {
          setStronglyDisagreePercentage(minimumBarPercentage);
        }
        else {
          setStronglyDisagreePercentage((numberStronglyDisagree / numberTotalVotes) * adjustedTotalPercentage);
        }

        if (((numberSomewhatDisagree / scale) * 100) < minimumBarPercentage) {
          setSomewhatDisagreePercentage(minimumBarPercentage);
        }
        else {
          setSomewhatDisagreePercentage((numberSomewhatDisagree / numberTotalVotes) * adjustedTotalPercentage);
        }

        if (((numberNeutral / scale) * 100) < minimumBarPercentage) {
          setNeutralPercentage(minimumBarPercentage);
        }
        else {
          setNeutralPercentage((numberNeutral / numberTotalVotes) * adjustedTotalPercentage);
        }

        if (((numberSomewhatAgree / scale) * 100) < minimumBarPercentage) {
          setSomewhatAgreePercentage(minimumBarPercentage);
        }
        else {
          setSomewhatAgreePercentage((numberSomewhatAgree / numberTotalVotes) * adjustedTotalPercentage);
        }

        if (((numberStronglyAgree / scale) * 100) < minimumBarPercentage) {
          setStronglyAgreePercentage(minimumBarPercentage);
        }
        else {
          setStronglyAgreePercentage((numberStronglyAgree / numberTotalVotes) * adjustedTotalPercentage);
        }

        if (numberTotalVotes >= 5) {
          setShouldDrawBar(true);
        }

        // console.log(`stronglyDisagreePercentage: ${stronglyDisagreePercentage}`);
        // console.log(`somewhatDisagreePercentage: ${somewhatDisagreePercentage}`);
        // console.log(`neutralPercentage: ${neutralPercentage}`);
        // console.log(`somewhatAgreePercentage: ${somewhatAgreePercentage}`);
        // console.log(`stronglyAgreePercentage: ${stronglyAgreePercentage}`);
        // console.log(`numberBelowVotesThreshold: ${numberBelowVotesTreshold}`);
        // console.log(`total percentage: ${stronglyDisagreePercentage + somewhatDisagreePercentage + neutralPercentage + somewhatAgreePercentage + stronglyAgreePercentage}`);
    }

    const totalNumberVotes = () => {
        return numberStronglyDisagree + numberSomewhatDisagree + numberNeutral + numberSomewhatAgree + numberStronglyAgree;
    }

    const calculateNumberBelowVotesTreshold = (numberTotalVotes) => {
        var n = 0;
        if (((numberStronglyDisagree / numberTotalVotes) * 100) < minimumBarPercentage) {
            n++;
        }
        if (((numberSomewhatDisagree / numberTotalVotes) * 100) < minimumBarPercentage) {
            n++;
        }
        if (((numberNeutral / numberTotalVotes) * 100) < minimumBarPercentage) {
            n++;
        }
        if (((numberSomewhatAgree/ numberTotalVotes) * 100) < minimumBarPercentage) {
            n++;
        }
        if (((numberStronglyAgree / numberTotalVotes) * 100) < minimumBarPercentage) {
            n++;
        }
        return n;
    }

    return (
    <div className="votes-bar">
       { shouldDrawBar ? (
        <>
          <div className="bar-element" style={{ width: `${stronglyDisagreePercentage}%` }}>
            <div className="bar-element-drawn stronglyDisagree" />
          </div>
          <div className="bar-element" style={{ width: `${somewhatDisagreePercentage}%` }}>
            <div className="bar-element-drawn somewhatDisagree" />
          </div>
          <div className="bar-element" style={{ width: `${neutralPercentage}%` }}>
            <div className="bar-element-drawn neutral"/>
          </div>
          <div className="bar-element" style={{ width: `${somewhatAgreePercentage}%` }}>
            <div className="bar-element-drawn somewhatAgree" />
          </div>
          <div className="bar-element" style={{ width: `${stronglyAgreePercentage}%` }}>
            <div className="bar-element-drawn stronglyAgree"/>
          </div>
        </>
      ) : (
          <h5 className="bar-label-no-votes">Not enough Votes yet.</h5>
      )}
    </div>
    );
}


export default VotesBar;