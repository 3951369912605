import { useState, useEffect } from 'react'
import NavigationBar from "./NavigationBar";
import LandingPage from "./LandingPage";
import Onboarding from "./Onboarding";
import Takes from "./Takes";

import TranslatedApp from "./TranslatedApp"

import './App.css';

import { Switch, BrowserRouter, Routes, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function App() {

  return (
    <TranslatedApp>
    <BrowserRouter>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={500}
              classNames="fade"
            >
              <Switch location={location}>
                <Route path="/" exact component={LandingPage} />
                <Route path="/onboarding" component={Onboarding} />
                <Route path="/takes" component={Takes} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
  </BrowserRouter>
  </TranslatedApp>
  );
}

export default App;