import { useState, useMemo } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import './Onboarding.css';
import { useTranslation } from 'react-i18next';
import OnboardingQuestion from './OnboardingQuestion.jsx';

export default function Onboarding() {
  const { t, i18n } = useTranslation();

  const stepsData = [
    {
      title: t('location.title'),
      text: t('location.text'),
      options: [
        { id: 0, value: t('location.europe') },
        { id: 1, value: t('location.northAmerica') },
        { id: 2, value: t('location.southAmerica') },
        { id: 3, value: t('location.asia') },
        { id: 4, value: t('location.africa') },
      ],
      key: 'location',
    },
    {
      title: t('age.title'),
      text: t('age.text'),
      options: Array.from({ length: 83 }, (_, i) => ({ id: i, value: i + 18 })),
      key: 'age',
    },  
  ];

  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedValues, setSelectedValues] = useState({});

  const handleInputStateChange = (key, option) => {
    setSelectedValues((prevState) => ({ ...prevState, [key]: option }));
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFinish = () => {
    console.log(`Finish!`);
    history.push('/takes');
  };

  const isNextEnabled = selectedValues[stepsData[activeStep].key] !== undefined;

  return (
    <div className="onboarding">
      <div className="onboarding__content">
        <div className="onboarding__steps">
          <TransitionGroup>
            <CSSTransition
              key={activeStep}
              classNames="onboarding__content__fade"
              timeout={500}
            >
              
              <OnboardingQuestion
                title={stepsData[activeStep].title}
                text={stepsData[activeStep].text}
                options={stepsData[activeStep].options}
                onSelect={(option) =>
                  handleInputStateChange(stepsData[activeStep].key, option)
                }
                selectedOption={selectedValues[stepsData[activeStep].key]}
              />

            </CSSTransition>
          </TransitionGroup>
        </div>
        <div className="onboarding-buttons">
          {activeStep !== 0 && (
            <button onClick={handleBack} className="onboarding-button button-left">
              Previous
            </button>
          )}
          {activeStep !== stepsData.length - 1 ? (
            <button onClick={handleNext} className="onboarding-button button-right" disabled={!isNextEnabled}>
              Next
            </button>
          ) : (
            <button onClick={handleFinish} className="onboarding-button button-right" disabled={!isNextEnabled}>
              Finish
            </button>
          )}
        </div>
      </div>
    </div>
  );
}


