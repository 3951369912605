import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationFR from './locales/fr/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...translationEN
      }
    },
    de: {
      translation: {
        ...translationDE
      }
    },
    fr: {
      translation: {
        ...translationFR
      }
    }
  },
  fallbackLng: 'en',
  lng: 'en', // Default language
  debug: true,
  detection: {
    order: ['cookie', 'navigator']
  },
  interpolation: {
    escapeValue: false
  }
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);