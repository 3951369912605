import React, { useState, useRef, useEffect } from 'react';
import './LanguageSwitcher.css';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher({ onLanguageChange }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const changeLanguage = (lng) => {
    setIsOpen(false);

    onLanguageChange(lng);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleEvents = (event) => handleClickOutside(event);
    document.addEventListener('click', handleEvents);
    document.addEventListener('touchend', handleEvents);
    return () => {
      document.removeEventListener('click', handleEvents);
      document.removeEventListener('touchend', handleEvents);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropbtn" onClick={toggleDropdown}>{t('language')}</button>
      <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
        <button type="button" className={`dropdown-item ${isOpen ? 'show' : ''}`} onClick={() => changeLanguage('en')}>{t('english')}</button>
        <button type="button" className={`dropdown-item ${isOpen ? 'show' : ''}`} onClick={() => changeLanguage('de')}>{t('german')}</button>
        <button type="button" className={`dropdown-item ${isOpen ? 'show' : ''}`} onClick={() => changeLanguage('fr')}>{t('french')}</button>
      </div>
    </div>
  );
}

export default LanguageSwitcher;